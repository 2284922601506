import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';

import { StyledSection } from './styles/StyledSection';
import { LinksWrapper } from './styles/LinksWrapper';
import { LinkWrapper } from './styles/LinkWrapper';
import { StyledLink } from './styles/StyledLink';
import { Summary } from './styles/Summary';
import { Title } from './styles/Title';

const PressSection = ({
  links,
  title,
}) => {
  const intl = useIntl();
  return (
    <StyledSection>
      <Title
        variant="heading1"
        color="black"
        intlKey={title}
        padding="6x"
      />
      <LinksWrapper>
        {links.map(({
          summary, linkText, linkUrl,
        }) => (
          <LinkWrapper
            intlKey={linkUrl}
            key={linkUrl}
          >
            <Summary
              variant="heading4"
              color="black"
              showOnDesktop
              intlKey={summary}
            />

            <Box
              display="flex"
              justifyContent={{ xs: 'center', md: 'left', lg: 'left' }}
            >

              <StyledLink
                variant="action"
                target="_blank"
                href={intl.formatMessage({ id: linkUrl })}
                intlKey={linkText}
              />
            </Box>
          </LinkWrapper>
        ))}
      </LinksWrapper>
    </StyledSection>
  );
};

PressSection.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    linkText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
  })).isRequired,
  title: PropTypes.string.isRequired,
};

export default PressSection;
