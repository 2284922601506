import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import { css } from 'styled-components';

import VerticalProductCardContainer from './styles/VerticalProductCardContainer';

const VerticalProductCard = ({
  headingTag, subtitleTag, textTag, buttonTag, buttonVariant, buttonUrlTag,
  backgroundColor, headingColor, subtitleColor, textColor, buttonColor,
}) => {
  const { formatMessage } = useIntl();
  const headingLabel = useMemo(() => formatMessage({ id: headingTag }));
  const subtitleLabel = useMemo(() => formatMessage({ id: subtitleTag }));
  const textLabel = useMemo(() => formatMessage({ id: textTag }));
  const buttonUrl = useMemo(() => formatMessage({ id: buttonUrlTag }));
  return (
    <VerticalProductCardContainer
      backgroundColor={backgroundColor}
      headingColor={headingColor}
      subtitleColor={subtitleColor}
      textColor={textColor}
    >
      <div className="card">
        <div className="paragraphs">
          <Typography
            variant="heading1"
            className="heading"
            dangerouslySetInnerHTML={{ __html: headingLabel }}
          />
          <Typography
            className="subtitle"
            variant="heading4"
            dangerouslySetInnerHTML={{ __html: subtitleLabel }}
          />
          <Typography
            className="paragraph"
            variant="paragraph1"
            dangerouslySetInnerHTML={{ __html: textLabel }}
          />
        </div>
        <Button
          extended
          id="show-product-btn"
          css={css`color: ${buttonColor}`}
          styleVariant={buttonVariant}
          variant="contained"
          onClick={() => { window.location.href = buttonUrl; }}
          intlKey={buttonTag}
          iconProps={{ name: 'arrow-right' }}
        />
      </div>
    </VerticalProductCardContainer>
  );
};

VerticalProductCard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonTag: PropTypes.string.isRequired,
  buttonUrlTag: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  headingColor: PropTypes.string.isRequired,
  headingTag: PropTypes.string.isRequired,
  subtitleColor: PropTypes.string.isRequired,
  subtitleTag: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  textTag: PropTypes.string.isRequired,
};

export default VerticalProductCard;
